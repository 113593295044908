import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const BlacklistForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={4}>
        <Typography variant="body1" fontWeight={600}>
          Check if a wallet address is blacklisted
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Is blacklisted?"}
              placeholder={"TRUE / FALSE"}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Is blacklisted?</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={4}>
        <Typography variant="body1" fontWeight={600}>
          Include or exclude blacklist function for wallet address
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Status"} placeholder={"Include / Exclude"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Include / Exclude</SundeButton>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

export default BlacklistForm;
