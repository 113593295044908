import { Box, Divider, FormHelperText, Stack, Typography } from "@mui/material";

const RowText = ({
  start,
  end,
  divider,
  subText,
  helperText,
  error,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Stack direction={"row"}>
        {typeof start === "string" ? (
          <Typography color="#e0e0e0" pl={1}>
            {start}
          </Typography>
        ) : (
          start
        )}
        <Box sx={{ flexGrow: 1 }} />
        {typeof end === "string" ? (
          <Typography color="#e0e0e0">{end}</Typography>
        ) : (
          end
        )}
      </Stack>
      {divider ? <Divider /> : ""}
      <Stack direction={"row"}>
        {helperText && (
          <FormHelperText
            color="#e0e0e0"
            sx={{ paddingLeft: "8px" }}
            error={error}
          >
            {helperText}
          </FormHelperText>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {subText && <FormHelperText color="#e0e0e0">{subText}</FormHelperText>}
      </Stack>
    </Box>
  );
};

export default RowText;
