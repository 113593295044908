import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { fontSizes } from "index";
import AntSwitch from "./AntSwitch";
import { useState } from "react";

const SundeInput = ({
  startAdornment,
  endAdornment,
  helperText,
  error,
  subText,
  labelrest,
  label,
  active,
  sx,
  formControls,
  ...rest
}) => {
  const theme = useTheme();
  const [showHideInput, setShowHideInput] = useState(active);
  return (
    <FormControl variant="outlined" fullWidth {...formControls}>
      <Stack direction={"row"}>
        {label && (
          <Typography pl={1} color={theme.palette.text.primary} {...labelrest}>
            {label}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {active !== undefined && (
          <AntSwitch
            inputProps={{ "aria-label": "ant design" }}
            checked={showHideInput}
            onChange={(e) => {
              setShowHideInput(e.target.checked);
            }}
          />
        )}
      </Stack>

      {(showHideInput || showHideInput === undefined) && (
        <>
          <OutlinedInput
            id="outlined-adornment-weight"
            endAdornment={
              endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              )
            }
            startAdornment={
              startAdornment && (
                <InputAdornment position="end">{startAdornment}</InputAdornment>
              )
            }
            aria-describedby={`helper-text-${label}`}
            inputProps={{
              "aria-label": "weight",
            }}
            sx={{
              background: "#2a2a2a",
              borderRadius: "12px",
              marginTop: 0.35,
              ...sx,
              "& input": {
                padding: "8px 0 8px 10px",
                fontSize: fontSizes.size6,
                ...sx?.["& input"],
              },
              "& fieldset": {
                border: "none",
                ...sx?.["& fieldset"],
              },
            }}
            {...rest}
          />
          <Stack direction={"row"}>
            {helperText && (
              <FormHelperText id={`helper-text-${label}`} error={error}>
                {helperText}
              </FormHelperText>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {subText && <FormHelperText>{subText}</FormHelperText>}
          </Stack>
        </>
      )}
    </FormControl>
  );
};

export default SundeInput;
