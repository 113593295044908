import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { commonReducer, commonSlice } from "./commonSlice";

const reducers = {
  [commonSlice.name]: commonReducer,
};
const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
