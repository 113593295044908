import { CssBaseline } from "@mui/material";
import Dashboard from "features/Dashboard/Dashboard";
import DashboardHome from "features/Dashboard/DashboardHome";
import TokenGanerator from "features/TokenGanerator/TokenGanerator";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<DashboardHome />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/tokenganerator" element={<TokenGanerator />} />
      </Routes>
    </>
  );
}

export default App;
