import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const AutoburntaxForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current taxes being collection for auto burn function
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"} mt={1}>
          <Typography variant="body1" color="#e0e0e0">
            BUY{" "}
            <Typography variant="body1" component="span" color="green">
              0.4 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            SELL{" "}
            <Typography variant="body1" component="span" color="green">
              0.6 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TRANSFER{" "}
            <Typography variant="body1" component="span" color="green">
              0 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TOTAL{" "}
            <Typography variant="body1" component="span" color="green">
              1 %
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change the amount of auto burn tax
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Buy tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />

          <Grid item xs={12} sm={3}>
            <SundeInput label={"Sell tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Transfer tax"} endAdornment={"%"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update burn tax</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Burn wallet address
        </Typography>

        <Stack sx={{ pl: "10%", my: 2 }}>
          <Typography
            variant="body1"
            component={"span"}
            fontWeight={600}
            color="green"
          >
            0x000000000000000000000000000000000e79.....
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AutoburntaxForm;
