import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const RewardstaxForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current fees set -
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"} mt={1}>
          <Typography variant="body1" color="#e0e0e0">
            BUY{" "}
            <Typography variant="body1" component="span" color="green">
              0.3 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            SELL{" "}
            <Typography variant="body1" component="span" color="green">
              0.5 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TRANSFER{" "}
            <Typography variant="body1" component="span" color="green">
              0 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TOTAL{" "}
            <Typography variant="body1" component="span" color="green">
              0.8 %
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change the amount of tax collected for rewards
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Buy tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />

          <Grid item xs={12} sm={3}>
            <SundeInput label={"Sell tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Transfer tax"} endAdornment={"%"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update taxes</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Exclude from rewards{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (Check to see if wallet address is excluded from rewards)
          </Typography>
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Address"}
              placeholder={"Enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={"auto"}>
            <SundeInput placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Is excluded?</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Exclude or include a wallet from rewards
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Address"}
              placeholder={"Enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={"auto"}>
            <SundeInput placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Include/exclude</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default RewardstaxForm;
