// import { Grid } from "@mui/material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import GradientBox from "components/GradientBox";
import Layout from "components/Layout/Layout";
import RowText from "components/RowText";
// import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import { useNavigate } from "react-router-dom";
// import React from "react";

const StyledButton = styled(Button)({
  backgroundColor: "#17a653",
  "&:hover": { opacity: 0.75, backgroundColor: "#17a653" },
  // backgroundColor: "#2A2A2A",
  // "&:hover": { opacity: 0.75, backgroundColor: "#2A2A2A" },
  border: "3px solid #fff",
  borderRadius: "12px",
  padding: "3px 8px",
  color: "#fff",
});
const DashboardHome = () => {
  const navigate = useNavigate();
  return (
    <Layout
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <GradientBox
        sx={{
          width: "100%",
          maxWidth: "900px",
          height: "500px",
          overflow: "auto",
          p: 2,
          m: 2,
        }}
      >
        <Stack direction={"row"} justifyContent={"center"}>
          <Box sx={{ backgroundColor: "#2a2a2a", borderRadius: "8px", p: 1 }}>
            <Typography variant="h5">Token Owners Dashboards</Typography>
            <Typography variant="body1" textAlign={"center"}>
              <Typography
                variant="body1"
                component={"span"}
                color="green"
                sx={{ textDecoration: "underline" }}
              >
                CONNECT
              </Typography>{" "}
              YOUR WALLET TO BEGIN
            </Typography>
          </Box>
        </Stack>
        <Grid container my={2} rowSpacing={4}>
          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box sx={{ width: "300px" }}>
                <Typography>Search for tokens I own</Typography>
                <SundeInput
                  multiline={true}
                  rows={4}
                  placeholder="Search for tokens your wallet did not deploy but have had ownership transferred to the wallet."
                  sx={{ "& textarea": { textAlign: "center" } }}
                />
                <StyledButton fullWidth>Search</StyledButton>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box sx={{ width: "300px" }}>
                <Typography>Your linked tokens on ETHEREUM chain</Typography>
                <Box
                  sx={{ background: "#2a2a2a", borderRadius: "12px", py: 2 }}
                >
                  <Typography color="green" px={2}>
                    Tokenx(TKNX) ♦
                  </Typography>
                  <RowText
                    sx={{ px: 2, pt: 2 }}
                    start={"Token Address"}
                    end={"0xa54..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Owner Address"}
                    end={"0x39a..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Deployer Address"}
                    end={"0xb83..."}
                  />
                  <Stack mx={2}>
                    <StyledButton
                      sx={{ my: 2 }}
                      onClick={() => navigate("/dashboard")}
                      fullWidth
                    >
                      View / edit token
                    </StyledButton>
                    <StyledButton fullWidth>Verify your contract</StyledButton>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box sx={{ width: "300px" }}>
                <Typography>Your linked tokens on ETHEREUM chain</Typography>
                <Box
                  sx={{ background: "#2a2a2a", borderRadius: "12px", py: 2 }}
                >
                  <Typography color="green" px={2}>
                    Tokenx(TKNX) ♦
                  </Typography>
                  <RowText
                    sx={{ px: 2, pt: 2 }}
                    start={"Token Address"}
                    end={"0xa54..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Owner Address"}
                    end={"0x39a..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Deployer Address"}
                    end={"0xb83..."}
                  />
                  <Stack mx={2}>
                    <StyledButton
                      sx={{ my: 2 }}
                      onClick={() => navigate("/dashboard")}
                      fullWidth
                    >
                      View / edit token
                    </StyledButton>
                    <StyledButton fullWidth>Verify your contract</StyledButton>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box sx={{ width: "300px" }}>
                <Typography>Your linked tokens on ETHEREUM chain</Typography>
                <Box
                  sx={{ background: "#2a2a2a", borderRadius: "12px", py: 2 }}
                >
                  <Typography color="green" px={2}>
                    Tokenx(TKNX) ♦
                  </Typography>
                  <RowText
                    sx={{ px: 2, pt: 2 }}
                    start={"Token Address"}
                    end={"0xa54..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Owner Address"}
                    end={"0x39a..."}
                  />
                  <Divider sx={{ my: 1 }} />
                  <RowText
                    sx={{ px: 2 }}
                    start={"Deployer Address"}
                    end={"0xb83..."}
                  />
                  <Stack mx={2}>
                    <StyledButton
                      sx={{ my: 2 }}
                      onClick={() => navigate("/dashboard")}
                      fullWidth
                    >
                      View / edit token
                    </StyledButton>
                    <StyledButton fullWidth>Verify your contract</StyledButton>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </GradientBox>
    </Layout>
  );
};

export default DashboardHome;
