import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const ForeigntokenrecoveryForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={4}>
        <Typography variant="body1" fontWeight={600}>
          Recover stuck erc20 tokens from contract
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={4} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"ERC20 token address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Amount"}
              placeholder={"e.g 100"}
              endAdornment={"Tokens"}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={4}>
          <SundeButton>Recover tokens</SundeButton>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

export default ForeigntokenrecoveryForm;
