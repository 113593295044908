import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "redux/store";
// import { deepOrange, grey } from "@mui/material/colors";

export const fontSizes = {
  size1: "2.25rem",
  size2: "1.5rem",
  size3: "1.25rem",
  size4: "1rem",
  size5: "0.875rem",
  size6: "0.75rem",
  size7: "0.625rem",
};
const theme = createTheme({
  palette: {
    mode: "dark",
    // palette values for dark mode
    // primary: black,
    divider: "#9e9e9e",
    // background: {
    //   default: deepOrange[900],
    //   paper: deepOrange[900],
    // },
    text: {
      primary: "#FEFEFE",
      secondary: "#9e9e9e",
    },
  },
  typography: {
    body1: {
      fontSize: fontSizes.size6,
      fontWeight: "400",
    },
    body2: {
      fontSize: fontSizes.size7,
    },
    button: {
      fontSize: fontSizes.size6,
      fontWeight: "700",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.7rem",
          fontWeight: 600,
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
