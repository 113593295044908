import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { BiMenuAltRight } from "react-icons/bi";
// import { CiMenuKebab } from "react-icons/ci";
import SundeButton from "components/SundeButton";
import { TbWallet } from "react-icons/tb";
import { IoMdSpeedometer } from "react-icons/io";
import { GiMoebiusStar } from "react-icons/gi";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          borderBottom: "1px solid #fff",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <Box sx={{ display: { md: "none", xs: "block" } }}>
              <CiMenuKebab fontSize={"x-large"} />
            </Box> */}
            <img src="./logo.png" height={"30px"} alt="logo" />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <BiMenuAltRight />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem sx={{ margin: "auto 0" }}>
                  <SundeButton
                    icon={<GiMoebiusStar />}
                    onClick={() => navigate("/tokenganerator")}
                    sx={{ fontSize: "10px" }}
                  >
                    GENERATE TOKEN
                  </SundeButton>
                </MenuItem>
                <MenuItem sx={{ margin: "auto 0" }}>
                  <SundeButton
                    icon={<IoMdSpeedometer />}
                    onClick={() => navigate("/")}
                    sx={{ fontSize: "10px" }}
                  >
                    DASHBOARD
                  </SundeButton>
                </MenuItem>
                <MenuItem sx={{ margin: "auto 0" }}>
                  <SundeButton icon={<TbWallet />} sx={{ fontSize: "10px" }}>
                    Connect Wallet
                  </SundeButton>
                </MenuItem>
              </Menu>
            </Box>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <SundeButton
                icon={<GiMoebiusStar />}
                onClick={() => navigate("/tokenganerator")}
                sx={{ fontSize: "10px" }}
              >
                GENERATE TOKEN
              </SundeButton>
              <SundeButton
                icon={<IoMdSpeedometer />}
                onClick={() => navigate("/")}
                sx={{ fontSize: "10px" }}
              >
                DASHBOARD
              </SundeButton>
              <SundeButton icon={<TbWallet />} sx={{ fontSize: "10px" }}>
                Connect Wallet
              </SundeButton>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ py: 1 }}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Button variant="contained" onClick={()=> navigate("/tokenganerator")}>Token Generator</Button>
          {/* <Typography variant="caption" >Token Generator</Typography> */}
          <SundeButton sx={{ fontSize: "10px", borderRadius: "8px" }}>
            How To Guide
          </SundeButton>

          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="caption">
            Token Holdings - <Button variant="contained">(Connect Wallet)</Button> DeployerX
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
export default Navbar;
