import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const TaxesgeneralForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current fees set -
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Typography variant="body1" color="#e0e0e0">
            BUY{" "}
            <Typography variant="body1" component="span" color="green">
              5 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            SELL{" "}
            <Typography variant="body1" component="span" color="green">
              7 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TRANSFER{" "}
            <Typography variant="body1" component="span" color="green">
              0 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TOTAL{" "}
            <Typography variant="body1" component="span" color="green">
              12 %
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Checks if a wallet address is excluded from taxes
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Is excluded"} placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Is excluded?</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Exclude from limits{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (this can be exclude or include a wallet address)
          </Typography>
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Is excluded"} placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Include/Exclude</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Swap threshold{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            - (Current amount of tokens tax collect before swapping to ETH)
          </Typography>
        </Typography>
        <Typography
          component={"h6"}
          sx={{ padding: "10px 0", fontSize: "0.75rem" }}
        >
          AMOUNT: 1000 TKNX
        </Typography>

        <SundeInput
          label={"Update swap threshold - Amounts of tokens"}
          placeholder={"Amount of tokens eg. 2,000"}
          endAdornment={"TKNX"}
        />

        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TaxesgeneralForm;
