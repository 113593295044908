import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import GradientBox from "components/GradientBox";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateGanerateTokenStep } from "redux/commonSlice";
const GaneralStep = () => {
  const dispatch = useDispatch();
  const [decimals, setDecimals] = useState(18);
  return (
    <Stack direction={"column"} spacing={2} px={2}>
      <Box>
        <GradientBox
          sx={{
            width: "100%",
            maxWidth: "334px",
            height: "500px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={600}
            pb={1}
          >
            Token Generator - General
          </Typography>
          <Divider />
          <Stack my={2} mx={4} rowGap={2}>
            <SundeInput label={"Network?"} placeholder="connect wallet" />
            <SundeInput label={"Token Name ?"} placeholder="e.g TokenX" />
            <SundeInput label={"Token Symbol ?"} placeholder="e.g TKNX" />
            <SundeInput
              label={"Token Supply ?"}
              placeholder="1000000"
              endAdornment={"Tokens"}
            />
            <SundeInput
              label={"Decimals ?"}
              value={decimals}
              startAdornment={
                <IconButton onClick={() => setDecimals((prev) => prev - 1)}>
                  <AiOutlineMinus />
                </IconButton>
              }
              endAdornment={
                <IconButton onClick={() => setDecimals((prev) => prev + 1)}>
                  <AiOutlinePlus />
                </IconButton>
              }
              sx={{
                "& input": {
                  textAlign: "center",
                  "-webkit-text-fill-color": "#fff !important",
                },
              }}
              disabled
            />
            <SundeInput
              label={"Change initial supply recipient ?"}
              placeholder="Recipient wallet address"
              active={false}
            />
            <SundeInput
              label={"Change token owner"}
              placeholder="Token owner wallet address"
              active={false}
            />
            <SundeInput
              label={"Watermark option ?"}
              placeholder="Write custom text"
              active={false}
            />
            <SundeInput
              label={"Anonymous wallet deployer ?"}
              placeholder="coming soon"
              active={false}
            />
          </Stack>
        </GradientBox>
      </Box>
      <SundeButton
        sx={{
          width: "fit-content",
          alignSelf: "center",
          backgroundColor: "#312f30",
          "&:hover": {
            backgroundColor: "#312f30",
            opacity: 0.75,
          },
          boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
        }}
        endIcon={<TbCircleArrowRightFilled />}
        onClick={() => dispatch(updateGanerateTokenStep(2))}
      >
        Step 2
      </SundeButton>
    </Stack>
  );
};

export default GaneralStep;
