import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import { FiHome } from "react-icons/fi";
import { VscSettingsGear } from "react-icons/vsc";
import { GiMoebiusStar } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateGanerateTokenStep } from "redux/commonSlice";

const CircleItem = styled(Box)(({ color }) => ({
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  color: color || "#fff",
  borderColor: color || "#fff",
  borderStyle: "solid",
  fontWeight: 600,
  fontSize: "1.25rem",
  borderWidth: "3px",
}));

const CircleBox = ({
  color = "red",
  value,
  label,
  key,
  buttonProps,
  ...rest
}) => (
  <Stack direction="column" alignItems={"center"} key={key} {...rest}>
    <IconButton {...buttonProps}>
      <CircleItem color={color}>{value}</CircleItem>
    </IconButton>
    <Typography mt={"-5px"}>{label}</Typography>
  </Stack>
);

export const stepsOptions = [
  { value: 1, label: "General" },
  { value: 2, label: "Functions" },
  { value: 3, label: "Taxes" },
  { value: 4, label: "Overview" },
];
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const step = useSelector((state) => state.commonSlice.ganerateTokenStep);
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={3}
      //   px={2}
      height={"100vh"}
      minWidth={"80px"}
      sx={{ display: { md: "flex", xs: "none" } }}
      borderRight={"1px solid #fff"}
    >
      <IconButton onClick={() => navigate("/")}>
        <FiHome style={{ fontSize: "xx-large" }} />
      </IconButton>
      {location.pathname !== "/tokenganerator" && (
        <Stack direction="column" alignItems={"center"}>
          <IconButton onClick={() => navigate("/tokenganerator")}>
            <GiMoebiusStar style={{ fontSize: "xx-large" }} />
          </IconButton>
          <Typography
            variant="caption"
            textAlign={"center"}
            lineHeight={"0.75rem"}
          >
            Token
            <br /> Generator
          </Typography>
        </Stack>
      )}
      {location.pathname === "/tokenganerator" &&
        stepsOptions.map((option) => (
          <CircleBox
            value={option.value}
            label={option.label}
            key={option.value + option.label}
            buttonProps={{
              onClick: () => dispatch(updateGanerateTokenStep(option.value)),
            }}
            color={
              step > option.value
                ? "green"
                : step === option.value
                ? "red"
                : "#fff"
            }
          />
        ))}
      <IconButton>
        <VscSettingsGear style={{ fontSize: "xx-large" }} />
      </IconButton>
    </Stack>
  );
};

export default Sidebar;
