import { createSlice } from "@reduxjs/toolkit";

const initialState = { ganerateTokenStep: 1 };

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    updateGanerateTokenStep(state, action) {
      state.ganerateTokenStep = action.payload;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const { updateGanerateTokenStep } = commonSlice.actions;
