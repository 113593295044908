import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Stack direction={"row"} p={1}>
      <Stack>
        <Typography variant="caption" lineHeight={1.1} onClick={()=> navigate("/")}>
          Home
        </Typography>
        <Typography variant="caption" lineHeight={1.1} onClick={()=> navigate("/tokenganerator")}>
          Generator
        </Typography>
        <Typography variant="caption" lineHeight={1.1} onClick={()=> navigate("/dashboard")}>
          Dashboard
        </Typography>
      </Stack>
      <Stack mx={2}>
        <Typography variant="caption" lineHeight={1.1}>
        <a href="//facebook.com" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}>FAQ</a>
        </Typography>
        <Typography variant="caption" lineHeight={1.1}>
        <a href="//terms.com" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}>Terms of Service</a>
        </Typography>
        <Typography variant="caption" lineHeight={1.1}>
        <a href="//privacy.com" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: "white"}}>Privacy Policy</a>
        </Typography>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        sx={{ display: "flex", height: "100%", justifyContent: "flex-end" }}
      >
        <img src="./logo2.png" height={"20px"} alt="img" />
      </Stack>
    </Stack>
  );
};

export default Footer;
