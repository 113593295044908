import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const RewardstrackerForm = () => {
  return (
    <Stack mb={2} style={{backgroundColor: "#121212"}}>
      <Box px={4} pt={2} py={1}>
        <Typography variant="body1" fontWeight={600}>
          Rewards tracker contract address
        </Typography>

        <Stack sx={{ pl: "10%", mt: 1 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              0x0E79.....
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Box px={4} pt={2} py={1}>
        <Typography variant="body1" fontWeight={600}>
          Minimum tokens for rewards
        </Typography>

        <Stack sx={{ pl: "10%", mt: 1 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              100 TKNX
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Box px={4} pt={2} py={1}>
        <Typography variant="body1" fontWeight={600}>
          Gas added to each transaction for auto processing of transactions
        </Typography>

        <Stack sx={{ pl: "10%", mt: 1 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              350000 units
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change gas added for auto processing transactions
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={6}>
            <SundeInput placeholder="350000" endAdornment={"units"} />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={4} mt="auto">
            <SundeButton>Change gas</SundeButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box px={4} pt={2} py={1}>
        <Typography variant="body1" fontWeight={600}>
          current minimum wait time between rewards processing
        </Typography>

        <Stack sx={{ pl: "10%", mt: 1 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              10 minutes
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />

      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change claim rewards wait time
        </Typography>

        <Grid container rowGap={4} columnSpacing={4} mt={2}>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={4}>
            <SundeInput placeholder="10" />
          </Grid>
          <Grid item xs={12} sm={2} mt="auto">
            <SundeInput placeholder="Mins. Hours" />
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box px={4} pt={2} py={1}>
        <Typography variant="body1" fontWeight={600}>
          Amount of eligible rewards holders
        </Typography>

        <Stack sx={{ pl: "10%", my: 1 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              107 holders
            </Typography>
          </Typography>
        </Stack>

        <Typography variant="body1" fontWeight={600}>
          Information about a holders rewards
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={1}>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={6}>
            <SundeInput
              placeholder="enter a wallet address 0x0e..."
              endAdornment={"units"}
            />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={4} mt="auto">
            <SundeButton>Check info</SundeButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default RewardstrackerForm;
