import { Slider, Stack, Typography, styled } from "@mui/material";

const StyledSlider = styled(Slider)(() => ({
  color: "#3a8589",
  height: 5,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#bdbdbd",
    border: "1px solid #bdbdbd",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "#bdbdbd",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 8,
  },
  "& .MuiSlider-rail": {
    color: "#bfbfbf",
    opacity: undefined,
    height: 8,
    borderRadius: 0,
  },
}));

const SundeSlider = ({ valueText, value, onChange, ...rest }) => {
  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      spacing={1}
    >
      {valueText && (
        <Typography component={"span"}>
          {value?.length ? value[0] : ""}%
        </Typography>
      )}
      <StyledSlider
        getAriaLabel={(index) =>
          index === 0 ? "Minimum price" : "Maximum price"
        }
        value={value}
        onChange={onChange}
        defaultValue={[20, 40]}
        {...rest}
      />
      {valueText && (
        <Typography component={"span"}>
          {value?.length ? value[1] : ""}%
        </Typography>
      )}
    </Stack>
  );
};
export default SundeSlider;
