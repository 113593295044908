import { Box, Stack } from "@mui/material";
import Footer from "components/Footer";
import Navbar from "components/Navbar/Navbar";
import Sidebar from "components/SideBar/Sidebar";
import React from "react";

const Layout = ({ children, sx, ...rest }) => {
  return (
    <Box display={"flex"} width={"100%"} height={"100vh"}>
      <Sidebar />
      <Stack direction={"column"} width={"100%"}>
        <Navbar />
        <Box
          sx={{
            width: "100%",
            height: "auto",
            flexGrow: 1,
            py: 3,
            // py: { sm: 30, md: 0 },
            // background: "linear-gradient(45deg, #5B9EDB, #11012a)",
            background: "#5B9EDB",
            ...sx,
          }}
          {...rest}
        >
          {children}
        </Box>
        <Footer />
      </Stack>
    </Box>
  );
};

export default Layout;
