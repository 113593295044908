import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AntSwitch from "components/AntSwitch";
import GradientBox from "components/GradientBox";
import RowText from "components/RowText";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import { useState } from "react";
import {
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
} from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateGanerateTokenStep } from "redux/commonSlice";
const TaxesStep = () => {
  const [taxWallet1, setTaxWallet1] = useState(true);
  const [addtionalTaxWallet1, setAdditionalTaxWallet1] = useState(true);
  const [autoburnFunction, setAutoburnFunction] = useState(true);
  const [liquidityTax, setLiquidityTax] = useState(true);
  const dispatch = useDispatch();
  return (
    <Stack direction={"column"} spacing={2}>
      <Box>
        <GradientBox
          sx={{
            width: "100%",
            maxWidth: "334px",
            height: "500px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={600}
            pb={1}
          >
            Token Generator - Taxes
          </Typography>
          <Divider />
          <Stack my={2} mx={4} rowGap={2}>
            {/* additional tax wllet #1 */}
            <Stack>
              <RowText
                start={"Tax Wallet #1 ?"}
                end={
                  <AntSwitch
                    checked={taxWallet1}
                    onChange={(e) => setTaxWallet1(e.target.checked)}
                  />
                }
              />
              {taxWallet1 && (
                <>
                  <SundeInput placeholder="Name of your wallet eg dev/marketing" />
                  <SundeInput placeholder="wallet address to receive funds" />
                </>
              )}
            </Stack>
            {/* additional text wallet */}
            <Stack>
              <RowText
                start={"Additional tax wallet ?"}
                end={
                  <AntSwitch
                    checked={addtionalTaxWallet1}
                    onChange={(e) => setAdditionalTaxWallet1(e.target.checked)}
                  />
                }
              />
              {addtionalTaxWallet1 && (
                <>
                  <RowText
                    helperText={"Types of coin taxes sent in ?"}
                    subText={"+0.01 ETH"}
                  />
                  <SundeInput placeholder="Current token, coin (ETH), custom token" />
                </>
              )}
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <RowText
                start={"Auto burn function ?"}
                end={
                  <AntSwitch
                    checked={autoburnFunction}
                    onChange={(e) => setAutoburnFunction(e.target.checked)}
                  />
                }
              />
              {autoburnFunction && (
                <>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Buy"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Sells"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Transfer"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction="row" justifyContent={"flex-end"}>
                    <FormHelperText sx={{ mt: "-5px" }}>
                      +0.01 ETH
                    </FormHelperText>
                  </Stack>
                </>
              )}
            </Stack>

            <Divider />
            {/* liquidity tax */}
            <Stack spacing={1}>
              <RowText
                start={"Liquidity tax ?"}
                end={
                  <AntSwitch
                    checked={liquidityTax}
                    onChange={(e) => setLiquidityTax(e.target.checked)}
                  />
                }
              />
              {liquidityTax && (
                <>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Buy"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Sells"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SundeInput
                        label={"Transfer"}
                        active={true}
                        labelrest={{ pl: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction="row" justifyContent={"flex-end"}>
                    <FormHelperText sx={{ mt: "-5px" }}>
                      +0.01 ETH
                    </FormHelperText>
                  </Stack>
                </>
              )}
            </Stack>
            <SundeInput
              label={"Wallet Address for Ip tokens"}
              placeholder={"Trading pair address/burn address"}
            />
            <RowText
              start={"Reward tax ?"}
              end={<AntSwitch defaultChecked />}
            />
          </Stack>
          {/* second page on scroll taxes can't */}

          <Divider sx={{ mb: 1 }} />
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={600}
            pb={1}
          >
            Token Generator - Taxes can&#39;t
          </Typography>
          <Divider />
          <Stack my={2} mx={4} rowGap={2}>
            <SundeInput
              label={"Rewards send in ?"}
              placeholder={"Current coin(ETH),custom token"}
            />
            <Box>
              <Grid container columnSpacing={1}>
                <Grid item xs={4}>
                  <SundeInput
                    label={"Buy"}
                    active={true}
                    labelrest={{ pl: 0 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SundeInput
                    label={"Sells"}
                    active={true}
                    labelrest={{ pl: 0 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SundeInput
                    label={"Transfer"}
                    active={true}
                    labelrest={{ pl: 0 }}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent={"flex-end"}>
                <FormHelperText sx={{ mt: "-5px" }}>+0.01 ETH</FormHelperText>
              </Stack>
            </Box>
            <Divider />
            <SundeInput
              label={"Rewards threshold ?"}
              placeholder={"User must hold a amount to receive rewards"}
            />
            <Box>
              <Stack direction="row" justifyContent={"flex-start"}>
                <Typography
                  sx={{ color: (theme) => theme.palette.text.primary, pl: 1 }}
                >
                  Rewards auto distribution interval?
                </Typography>
              </Stack>
              <Grid container columnSpacing={1}>
                <Grid item xs={8}>
                  <SundeInput placeholder="Set your interval time" />
                </Grid>
                <Grid item xs={4}>
                  <SundeInput
                    placeholder="Mins,Hrs,Days"
                    labelrest={{ pl: 0 }}
                  />
                </Grid>
              </Grid>
            </Box>
            <SundeInput
              label={"Gas charged to process rewards ?"}
              placeholder="30000"
              endAdornment={"units"}
            />
            <Divider />
            <SundeInput
              label={"Gas charged to process rewards ?"}
              placeholder="30000"
              endAdornment={"units"}
            />
            <Divider />
          </Stack>
        </GradientBox>
      </Box>
      <Stack
        direction="row"
        justifyContent={"space-evenly"}
        sx={{ width: "100%", maxWidth: "334px" }}
      >
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          icon={<TbCircleArrowLeftFilled />}
          onClick={() => dispatch(updateGanerateTokenStep(2))}
        >
          Back
        </SundeButton>
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          endIcon={<TbCircleArrowRightFilled />}
          onClick={() => dispatch(updateGanerateTokenStep(4))}
        >
          Step 4
        </SundeButton>
      </Stack>
    </Stack>
  );
};

export default TaxesStep;
