import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import GradientBox from "components/GradientBox";
import RowText from "components/RowText";
import SundeButton from "components/SundeButton";
import { TbCircleArrowLeftFilled } from "react-icons/tb";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateGanerateTokenStep } from "redux/commonSlice";

const TextOverview = styled(Typography)({
  fontWeight: 600,
  color: "green",
});
const OverviewStep = () => {
  const dispatch = useDispatch();

  return (
    <Stack
      direction={"column"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <Box sx={{ width: "100%", maxWidth: "334px" }}>
        <GradientBox
          sx={{
            width: "100%",
            maxWidth: "334px",
            height: "500px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Stack>
            <Typography variant="body1" textAlign={"center"} fontWeight={600}>
              Token Generator
            </Typography>
            <Typography
              variant="body1"
              textAlign={"center"}
              fontWeight={600}
              pb={1}
            >
              Overview
            </Typography>
          </Stack>
          <Stack my={2} mx={2}>
            <RowText start={"Network"} end={"Ethereum"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Token Name"} end={"TokenX"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Token Symbol"} end={"TKNX"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Token Supply"} end={"10000000"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Decimals"} end={"18"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Initial supply recipent"} end={"Default"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Token owner address"} end={"OX......."} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Exchange for liquidity"} end={"UNI V2"} />
            {/* <RowText
              start={"Mintable, Max supply"}
              end={
                <Typography
                  sx={{
                    color: "#e0e0e0",
                    fontSize: "0.5rem",
                    marginTop: "auto",
                  }}
                >
                  Enabled 1000000 Token
                </Typography>
              }
            /> */}
            <Divider sx={{ my: 1 }} />
            <RowText start={"Max transaction limit"} end={"30000000"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Max Wallet limit"} end={"2000000000"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Mintable Y/N"} end={"4000000000"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Mintable Max supply"} end={"1000000000"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Pausable function Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Blacklist function Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Cooldown"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Tax wallet #1 Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Tax wallet name"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Anti burn function - Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Buys % Sells % Transfer %"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Liquidity tax - Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Buys % Sells % Transfer %"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Liquidity tax recipent"} end={"Wallet Address"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Rewards tax - Y/N"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Buys % Sells % Transfer %"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Rewards sent in - eg ETH"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText
              start={"Rewards sent to holder with ? eg TKNX tokens"}
              end={"N/A"}
            />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Buys % Sells % Transfer %"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Rewards threshold"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Rewards Auto distribtution"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Gas charge to process rewards"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"Swap threshold"} end={"N/A"} />
            <Divider sx={{ my: 1 }} />
            <RowText start={"TOTAL TAXES"} />
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <Stack direction={"column"} alignItems={"center"}>
                <TextOverview>BUYS</TextOverview>
                <Typography variant="h6">0%</Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"center"}>
                <TextOverview>SELLS</TextOverview>
                <Typography variant="h6">0%</Typography>
              </Stack>
              <Stack direction={"column"} alignItems={"center"}>
                <TextOverview>TRANSFERS</TextOverview>
                <Typography variant="h6">0%</Typography>
              </Stack>
            </Stack>
          </Stack>

          {/* <Stack my={2} mx={2}>
            <TextOverview>Network</TextOverview>
            <TextOverview>Token name</TextOverview>
            <TextOverview>Token symbol</TextOverview>
            <TextOverview>Token supply</TextOverview>
            <TextOverview>Decimals</TextOverview>
            <TextOverview>Initial suppy recipent</TextOverview>
            <TextOverview>Token owner address</TextOverview>
            <TextOverview>
              Anonymous wallet deployer -{" "}
              <Typography color="red" component={"span"}>
                Comming Soon
              </Typography>
            </TextOverview>
            <TextOverview>Exchange for liquidity</TextOverview>
            <TextOverview>Max trasaction limit</TextOverview>
            <TextOverview>Mas wallet limit</TextOverview>
            <TextOverview>Mintable Y/N</TextOverview>
            <TextOverview>Mintable Max supply</TextOverview>
            <TextOverview>Pausable function Y/N</TextOverview>
            <TextOverview>Blacklist function Y/N</TextOverview>
            <TextOverview>Cooldown -</TextOverview>
            <TextOverview>Tax wallet #1 Y/N</TextOverview>
            <TextOverview>Tax wallet name - </TextOverview>
            <TextOverview>Auti burn function - Y/N</TextOverview>
            <TextOverview>Buys % Sells % Transfer %</TextOverview>
            <TextOverview>Liquidity tax - Y/N</TextOverview>
            <TextOverview>Buys % Sells % Transfers %</TextOverview>
            <TextOverview>Liquidity tax - Y/N</TextOverview>
            <TextOverview>Buys % Sells % Transfers %</TextOverview>
            <TextOverview>Liquidity tax recipent - WALLET ADDRESS</TextOverview>
            <TextOverview>Rewards tax - Y/N</TextOverview>
            <TextOverview>Buys % Sells % Transfers %</TextOverview>
            <TextOverview>Rewards sent in - eg ETH</TextOverview>
            <TextOverview>
              Rewards sent to holder with ? eg TKNX tokens
            </TextOverview>
            <TextOverview>Buys % Sells % Transfers %</TextOverview>
            <TextOverview>Rewards threshold</TextOverview>
            <TextOverview>Rewards Auto distribution - </TextOverview>
            <TextOverview>Gas charge to process rewards - </TextOverview>
            <TextOverview>Swap threshold</TextOverview>
           
          </Stack> */}
        </GradientBox>
      </Box>
      <Stack
        direction="row"
        justifyContent={"space-evenly"}
        sx={{ width: "100%", maxWidth: "334px" }}
      >
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          icon={<TbCircleArrowLeftFilled />}
          onClick={() => dispatch(updateGanerateTokenStep(3))}
        >
          Back
        </SundeButton>
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          endIcon={<TbCircleArrowRightFilled />}
          // onClick={() => dispatch(updateGanerateTokenStep(5))}
        >
          Compile
        </SundeButton>
      </Stack>
    </Stack>
  );
};

export default OverviewStep;
