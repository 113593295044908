import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const AntibotcooldownForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current cooldown time is set at -
        </Typography>
        <Typography variant="body1" color="#e0e0e0" ml={"10%"} mt={1}>
          e.g -{" "}
          <Typography variant="body1" color="green" component={"span"}>
            30 minutes
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Update trading cooldown time
        </Typography>

        <Grid container rowGap={4} columnSpacing={4} mt={2}>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={4}>
            <SundeInput label="New time" placeholder="15" />
          </Grid>
          <Grid item xs={12} sm={2} mt="auto">
            <SundeInput placeholder="Mins. Hours" />
          </Grid>
          <Grid item xs={12} sm={4} m="auto">
            <SundeButton>Change time</SundeButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Check last trade time buy or sell by wallet
        </Typography>

        <Grid container rowGap={4} columnSpacing={4} mt={2}>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={6}>
            <SundeInput
              label="Address"
              placeholder="Enter wallet address 0x...."
            />
          </Grid>

          <Grid item xs={12} sm={4} m="auto">
            <SundeButton>Check info</SundeButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Excluded from limits?{" "}
          <Typography variant="body1" color="#9e9e9e" component={"span"}>
            (check if wallet address is excluded from limits, max wallet, max
            transactions & max anti-boot cooldown)
          </Typography>
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Status"} placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Is excluded?</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Include or exclude address from limits
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Status"} placeholder={"Include / Exclude"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Include / Exclude</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AntibotcooldownForm;
