import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import AntSwitch from "components/AntSwitch";
import GradientBox from "components/GradientBox";
import RowText from "components/RowText";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import {
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
} from "react-icons/tb";
import { useDispatch } from "react-redux";
import { updateGanerateTokenStep } from "redux/commonSlice";
const FunctionStep = () => {
  const dispatch = useDispatch();
  return (
    <Stack direction={"column"} spacing={2}>
      <Box>
        <GradientBox
          sx={{
            width: "100%",
            maxWidth: "334px",
            height: "500px",
            overflow: "auto",
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={600}
            pb={1}
          >
            Token Generator - Function
          </Typography>
          <Divider />
          <Stack my={2} mx={4} rowGap={2}>
            <SundeInput
              label={"Exchange for adding liquidity?"}
              placeholder="connect wallet"
              active
            />
            <SundeInput
              label={"Max transaction limit % ?"}
              placeholder="Maximum buy per transaction %"
              active={true}
            />
            <Grid container columnSpacing={1}>
              <Grid item xs={4}>
                <SundeInput label={"Buy"} active={true} />
              </Grid>
              <Grid item xs={4}>
                <SundeInput label={"Sells"} active={true} />
              </Grid>
              <Grid item xs={4}>
                <SundeInput label={"Transfer"} active={true} />
              </Grid>
            </Grid>

            <SundeInput
              label={"Max wallet amount % ?"}
              placeholder="Total maximum tokens per wallet"
              active={false}
            />
            <SundeInput
              label={"Mintable ?"}
              placeholder="Max supply in tokens"
              active={false}
            />
            <RowText start={"Pause function ?"} end={<AntSwitch />} divider />
            <RowText
              start={"Blacklist function ?"}
              end={<AntSwitch />}
              subText={"+0.01 ETH"}
              divider
            />
            <RowText
              start={"Token contract recovery ?"}
              end={<AntSwitch />}
              divider
            />
            <SundeInput
              label={"Anti-bot cooldown ?"}
              placeholder="cooldown time"
              endAdornment={"Minutes"}
              subText={"+0.01 ETH"}
              active={false}
            />
          </Stack>
        </GradientBox>
      </Box>
      <Stack
        direction="row"
        justifyContent={"space-evenly"}
        sx={{ width: "100%", maxWidth: "334px" }}
      >
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          icon={<TbCircleArrowLeftFilled />}
          onClick={() => dispatch(updateGanerateTokenStep(1))}
        >
          Back
        </SundeButton>
        <SundeButton
          sx={{
            width: "fit-content",
            alignSelf: "center",
            backgroundColor: "#312f30",
            "&:hover": {
              backgroundColor: "#312f30",
              opacity: 0.75,
            },
            boxShadow: "-1px 1px 5px #000, 1px -1px 5px 0 #000",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
          }}
          endIcon={<TbCircleArrowRightFilled />}
          onClick={() => dispatch(updateGanerateTokenStep(3))}
        >
          Step 3
        </SundeButton>
      </Stack>
    </Stack>
  );
};

export default FunctionStep;
