import Layout from "components/Layout/Layout";
import GaneralStep from "./forms/GaneralStep";
import { useSelector } from "react-redux";
import FunctionStep from "./forms/FunctionStep";
import TaxesStep from "./forms/TaxesStep";
import OverviewStep from "./forms/OverviewStep";

const TokenGanerator = () => {
  const step = useSelector((state) => state.commonSlice.ganerateTokenStep);

  return (
    <Layout
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {
        {
          1: <GaneralStep />,
          2: <FunctionStep />,
          3: <TaxesStep />,
          4: <OverviewStep />,
        }[step]
      }
    </Layout>
  );
};

export default TokenGanerator;
