import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const TaxWallet1Form = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current fees being collection from wallet
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Typography variant="body1" color="#e0e0e0">
            BUY{" "}
            <Typography variant="body1" component="span" color="green">
              2.5 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            SELL{" "}
            <Typography variant="body1" component="span" color="green">
              3.5 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TRANSFER{" "}
            <Typography variant="body1" component="span" color="green">
              0 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TOTAL{" "}
            <Typography variant="body1" component="span" color="green">
              6 %
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Checks if a wallet address is excluded from taxes
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Buy tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />

          <Grid item xs={12} sm={3}>
            <SundeInput label={"Sell tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Transfer tax"} endAdornment={"%"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update Taxes</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Tax address wallet #1
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (this is wallet receiving taxes)
          </Typography>
        </Typography>

        <Stack sx={{ pl: "10%", my: 2 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              0x0e79.....
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change wallet recipient for tax wallet #1
        </Typography>

        <SundeInput
          label={"Address"}
          placeholder={"Enter recipient wallet address 0x0e..."}
          endAdornment={"TKNX"}
          sx={{ mx: "10%" }}
        />

        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update Address</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TaxWallet1Form;
