import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import React from "react";

const GeneralForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Total Supply
        </Typography>
        <Typography variant="body1" color="#e0e0e0">
          10,000,000 TKNX
        </Typography>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Token Decimals - The amount of decimals you set for your token
        </Typography>
        <Typography variant="body1" color="#e0e0e0">
          18 Decimals
        </Typography>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Transfer to address and amount to transfer
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} m={1}>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput label={"To"} placeholder={"enter wallet address"} />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput
              label={"Amount of Tokens"}
              placeholder={"eg 10,000"}
              endAdornment={"TKNX"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} mt={"auto"}>
            <SundeButton>Transfer</SundeButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Approve address to spend your tokens
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} m={1}>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput
              label={"Spender address"}
              placeholder={"enter wallet address"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput
              label={"Amount of Tokens"}
              placeholder={"eg 10,000"}
              endAdornment={"TKNX"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} mt={"auto"}>
            <SundeButton>Approve</SundeButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Burn your tokens
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} m={1}>
          <Grid item xs={12} sm={12} md={10}>
            <SundeInput
              label={"Amount of tokens to burn"}
              placeholder={"eg 50,000"}
              endAdornment={"TKNX"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} mt={"auto"}>
            <SundeButton>Burn</SundeButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
    
  );
};

export default GeneralForm;
