import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const MintableForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current mas mint supply
        </Typography>
        <Typography variant="body1" color="#e0e0e0">
          100,000,000 TKNX
        </Typography>
      </Box>
      <Divider />

      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Mint to wallet & amount
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={1} m={1}>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput label={"To"} placeholder={"enter wallet address"} />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <SundeInput
              label={"Amount"}
              placeholder={"eg 10,000"}
              endAdornment={"TKNX"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} mt={"auto"}>
            <SundeButton>Mint</SundeButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default MintableForm;
