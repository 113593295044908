import { Stack, Typography } from "@mui/material";

const SundeTabItem = ({ text, isActive, sx, ...rest }) => {
  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      py={0.5}
      borderRadius="8px"
      backgroundColor="#2a2a2a"
      sx={{
        cursor: "pointer",
        color: isActive ? "green" : "#e0e0e0",
        maxWidth: "240px",
        width: "100%",
        "&:hover": { color: "green" },
        ...sx,
      }}
      {...rest}
    >
      <Typography variant="caption">{text}</Typography>
    </Stack>
  );
};

export default SundeTabItem;
