import { Divider, Grid, Stack, Typography } from "@mui/material";
import GradientBox from "components/GradientBox";
import Layout from "components/Layout/Layout";
import featuresOptions from "./featuresOptions";
import SundeTabItem from "components/SundeTabItem";
import { useState } from "react";
import GeneralForm from "./forms/GeneralForm";
import MintableForm from "./forms/MintableForm";
import MaxtransactionlimitForm from "./forms/MaxtransactionlimitForm";
import MaxwalletholdingsForm from "./forms/MaxwalletholdingsForm";
import TaxesgeneralForm from "./forms/TaxesgeneralForm";
import TaxWallet1Form from "./forms/Taxwallet1Form";
import TaxWallet2Form from "./forms/Taxwallet2Form";
import LiquiditytaxForm from "./forms/LiquiditytaxForm";
import AutoburntaxForm from "./forms/AutoburntaxForm";
import RewardstaxForm from "./forms/RewardstaxForm";
import RewardstrackerForm from "./forms/RewardstrackerForm";
import PausableForm from "./forms/PausableForm";
import BlacklistForm from "./forms/BlacklistForm";
import AntibotcooldownForm from "./forms/AntibotcooldownForm";
import ForeigntokenrecoveryForm from "./forms/ForeigntokenrecoveryForm";

const Dashboard = () => {
  const [featureTab, setFeatureTab] = useState({
    label: "General",
    value: "general",
  });
  console.log("featureTab", featureTab);
  return (
    <Layout
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid container columnGap={4} p={1} rowGap={4} m={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={12} md={3}>
          <GradientBox
            sx={{
              width: "100%",
              height: "500px",
              background: "#121212",
              overflow: "auto",
              p: 2,
            }}
          >
            <Typography
              style={{ backgroundColor: "#121212" }}
              variant="h6"
              pb={1}
              textAlign={"center"}
              color={"#FEFEFE"}
            >
              FEATURES
            </Typography>
            <Divider />
            <Stack style={{ backgroundColor: "#121212" }}>
              <Stack
                direction={"column"}
                alignItems={"center"}
                p={2}
                spacing={1.5}
              >
                {featuresOptions.map((option, index) => (
                  <SundeTabItem
                    key={option.value + index}
                    text={option.label}
                    onClick={() =>
                      option.value !== "renounceOwnership" &&
                      setFeatureTab(option)
                    }
                    isActive={option.value === featureTab.value}
                  />
                ))}
              </Stack>
            </Stack>
          </GradientBox>
        </Grid>

        <Grid item xs={12} md={6}>
          <GradientBox
            sx={{ width: "100%", height: "500px", overflow: "auto" }}
          >
            <Typography
              variant="h6"
              textAlign={"center"}
              style={{ backgroundColor: "#121212" }}
            >
              DASHBOARD
            </Typography>
            <Typography
              variant="body1"
              textAlign={"center"}
              color={"#9e9e9e"}
              style={{ backgroundColor: "#121212" }}
            >
              {featureTab.label}
            </Typography>
            <Divider sx={{ borderColor: "#fff" }} />
            {
              {
                general: <GeneralForm />,
                maxtransactionlimit: <MaxtransactionlimitForm />,
                maxwalletholdings: <MaxwalletholdingsForm />,
                taxesgeneral: <TaxesgeneralForm />,
                taxwallet1: <TaxWallet1Form />,
                taxwallet2: <TaxWallet2Form />,
                liquiditytax: <LiquiditytaxForm />,
                autoburntax: <AutoburntaxForm />,
                rewardstax: <RewardstaxForm />,
                rewardstracker: <RewardstrackerForm />,
                mintable: <MintableForm />,
                pausable: <PausableForm />,
                blacklist: <BlacklistForm />,
                antibotcooldown: <AntibotcooldownForm />,
                foreigntokenrecovery: <ForeigntokenrecoveryForm />,
                renounceOwnership: "",
              }[featureTab.value]
            }
          </GradientBox>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
("");
