import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";

const LiquiditytaxForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current fees being collection from liquidity
        </Typography>
        <Stack direction={"row"} justifyContent={"space-around"}>
          <Typography variant="body1" color="#e0e0e0">
            BUY{" "}
            <Typography variant="body1" component="span" color="green">
              0.3 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            SELL{" "}
            <Typography variant="body1" component="span" color="green">
              1.3 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TRANSFER{" "}
            <Typography variant="body1" component="span" color="green">
              0 %
            </Typography>
          </Typography>
          <Typography variant="body1" color="#e0e0e0">
            TOTAL{" "}
            <Typography variant="body1" component="span" color="green">
              1.6 %
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change the amount of tax auto liquidity
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Buy tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />

          <Grid item xs={12} sm={3}>
            <SundeInput label={"Sell tax"} endAdornment={"%"} />
          </Grid>
          <Grid item xs={0} sm={1} />
          <Grid item xs={12} sm={3}>
            <SundeInput label={"Transfer tax"} endAdornment={"%"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update liq tax</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Lp token receivers{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            - (Current receiver of Lp token)
          </Typography>
        </Typography>

        <Stack sx={{ pl: "10%", my: 2 }}>
          <Typography variant="body1" fontWeight={600}>
            e.g{" "}
            <Typography
              variant="body1"
              component={"span"}
              fontWeight={600}
              color="green"
            >
              0x0e79.....
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Change wallet recipient for liquidity tokens
        </Typography>

        <SundeInput
          label={"Address"}
          placeholder={"Enter recipient wallet address 0x0e..."}
          endAdornment={"TKNX"}
          sx={{ mx: "10%" }}
        />

        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Update Address</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default LiquiditytaxForm;
