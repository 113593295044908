export default [
  { label: "General", value: "general" },
  { label: "Max transaction limit", value: "maxtransactionlimit" },
  { label: "Max wallet holdings", value: "maxwalletholdings" },
  { label: "Taxes general", value: "taxesgeneral" },
  { label: "Tax wallet #1", value: "taxwallet1" },
  { label: "Tax wallet #2 (if applicable)", value: "taxwallet2" },
  { label: "Liquidity tax", value: "liquiditytax" },
  { label: "Auto burn tax", value: "autoburntax" },
  { label: "Rewards tax", value: "rewardstax" },
  { label: "Rewards tracker", value: "rewardstracker" },
  { label: "Mintable", value: "mintable" },
  { label: "Pausable", value: "pausable" },
  { label: "Whitelist", value: "whitelist" },
  { label: "Blacklist", value: "blacklist" },
  { label: "Anti-bot cooldown", value: "antibotcooldown" },
  { label: "Foreign token recovery", value: "foreigntokenrecovery" },
  // { label: "Renounce Ownership", value: "renounceOwnership" },
];
