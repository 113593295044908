import { Box, Divider, Grid, Stack, Switch, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";
import SundeInput from "components/SundeInput";
import SundeSlider from "components/SundeSlider";
import React, { useState } from "react";

const MaxwalletholdingsForm = () => {
  const [sliderValue, setSliderValue] = useState([0, 40]);
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current max wallet holdings
        </Typography>
        <Typography variant="body1" color="#e0e0e0">
          2 %
        </Typography>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Update max holdings - Applies for buys, sells & transfers -{" "}
          <Typography variant="h6" component={"span"} fontWeight={600}>
            40%
          </Typography>
        </Typography>
        <SundeSlider
          valueText
          defaultValue={[0.1, 40]}
          step={0.1}
          value={sliderValue}
          onChange={(e, value) => {
            setSliderValue(value);
          }}
        />
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        <Typography variant="body1" fontWeight={600} textAlign={"center"}>
          Lift max holdings - No restriction applies for buys sells & transfers
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (overrides above setting)
          </Typography>
        </Typography>
        <Switch />
      </Stack>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Checks if a wallet address is excluded from limits{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (excludes from max transaction, max wallet & anti-bot cooldown)
          </Typography>
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Is excluded"} placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Is excluded?</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Exclude from limits{" "}
          <Typography variant="body1" color="text.secondary" component={"span"}>
            (this can be exclude or include a wallet address)
          </Typography>
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <SundeInput
              label={"Wallet address"}
              placeholder={"enter wallet address 0x..."}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SundeInput label={"Is excluded"} placeholder={"TRUE / FALSE"} />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={"center"} mt={2}>
          <SundeButton>Include/Exclude</SundeButton>
        </Stack>
      </Box>
    </Stack>
  );
};
export default MaxwalletholdingsForm;
