import { Box, styled } from "@mui/material";

const GradientBox = styled(Box)({
  background: "linear-gradient(1deg, #121212, #010101)",
  borderRadius: "25px",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: "2px",
    height: "2px",
  },

  "&::-webkit-scrollbar-track ": {
    background: "#f1f1f1",
    margin: "50px 50px",
    borderRadius: "50px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "50px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});

export default GradientBox;
