import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)({
  border: "1px solid #fff",
  backgroundColor: "#2A2A2A",
  borderRadius: "12px",
  color: "#fff",
  "&:hover": {
    color: "green",
    backgroundColor: "#2A2A2A",
    opacity: 0.75,
    border: "1px solid #fff",
  },
});
const SundeButton = ({ icon, endIcon, children, ...rest }) => {
  return (
    <StyledButton
      variant="outlined"
      startIcon={icon}
      endIcon={endIcon}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default SundeButton;
