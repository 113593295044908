import { Box, Divider, Stack, Typography } from "@mui/material";
import SundeButton from "components/SundeButton";

const PausableForm = () => {
  return (
    <Stack style={{backgroundColor: "#121212"}}>
      <Box px={4} py={2}>
        <Typography variant="body1" fontWeight={600}>
          Current pause status
        </Typography>
        <Typography variant="body1" color="#e0e0e0">
          Status -{" "}
          <Typography variant="body1" color="green" component={"span"}>
            TRUE / FALSE
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Box px={4} py={4}>
        <Typography variant="body1" fontWeight={600}>
          Pause trading
        </Typography>
        <Stack direction="row" justifyContent={"center"}>
          <SundeButton>Pause</SundeButton>
        </Stack>
      </Box>
      <Divider />
      <Box px={4} py={4}>
        <Typography variant="body1" fontWeight={600}>
          Unpause trading
        </Typography>
        <Stack direction="row" justifyContent={"center"}>
          <SundeButton>Pause</SundeButton>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

export default PausableForm;
